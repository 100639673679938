<template>
  <div class="hidden"></div>
</template>

<script setup lang="ts">
/**
 * ================================
 * Global Imports
 * ================================
 */

import { type Channel } from 'laravel-echo'

/**
 * ================================
 * Local Imports
 * ================================
 */
import useAuthStore from '~/features/auth/store'
import factories from '~/factories'
import { printStrategies } from '~/features/printer/store/printer-class.client'
import type {
  channelCarrierLabelsPayloadType,
  channelDeliveryNotesPayloadType
} from '~/features/printer/store/types'
import { usePickingDashboardStore } from '~/features/warehouse/picking-dashboard/store'

/**
 * ================================
 * Stores
 * ================================
 */
const authStore = useAuthStore()
const warehouseOrdersStore = usePickingDashboardStore()
const echo = useEcho()

/**
 * ================================
 * Refs
 * ================================
 */
const channelPrintDeliveryNotes = ref<{
  name: string | null
  channel: Channel | null
  listening: boolean
}>({
  name: null,
  channel: null,
  listening: false
})

const channelPrintShipmentLabels = ref<{
  name: string | null
  channel: Channel | null
  listening: boolean
}>({
  name: null,
  channel: null,
  listening: false
})

/**
 * ================================
 * Computed
 * ================================
 */

const user = useUser()
const country = computed(() => authStore.selectedCountry.name)
const warehouseLocation = computed(() => warehouseOrdersStore.warehouseLocation)
const printerConfigs = computed(() => authStore.printer.printerConfigs)

const delivery_notes = computed(() =>
  printerConfigs.value.find(({ file_name }) => file_name === 'delivery_notes')
)

const printedDeliveryNotes = computed({
  get: () => warehouseOrdersStore.printedDeliveryNotes,
  set: (val) => {
    warehouseOrdersStore.printedDeliveryNotes = val
  }
})

const carrier_labels = computed(() =>
  printerConfigs.value.find(({ file_name }) => file_name === 'carrier_labels')
)

/**
 * ================================
 * Methods
 * ================================
 */

/**
 * ================================
 * Delivery Notes
 */

const listenForDeliveryNotes = async (
  auto_print?: boolean,
  country?: string,
  userId?: number
) => {
  if (!auto_print || !country || !userId) return

  const printed = printedDeliveryNotes.value

  const { name: channelName } = channelPrintDeliveryNotes.value

  if (channelName !== `warehouse-dashboard-print-orders-${country}-${userId}`) {
    leaveDeliveryNotes()
  }

  if (channelPrintDeliveryNotes.value.listening) return false

  const newChannelName =
    (channelPrintDeliveryNotes.value.name = `warehouse-dashboard-print-orders-${country}-${userId}`)

  if (!channelPrintDeliveryNotes.value.channel) {
    // Join the channel
    channelPrintDeliveryNotes.value.channel = echo.channel(newChannelName)
    // Log
    console.log('🖨️ Joined delivery notes channel', newChannelName)
    // Error handling
    channelPrintDeliveryNotes.value.channel.error((error: unknown) =>
      console.log('Error', error)
    )
  }

  if (
    channelPrintDeliveryNotes.value.channel &&
    !channelPrintDeliveryNotes.value.listening
  ) {
    channelPrintDeliveryNotes.value.channel.listen(
      '.warehouse-print-order-event',
      async (payload: channelDeliveryNotesPayloadType) => {
        // Destructure payload
        const { syspro_invoice_id, syspro_order_id } = payload.data

        console.log('📦 Print payload', payload)

        // If it's not been printed, proceed with printing
        const checkIfPrinted = printed.includes(syspro_invoice_id)

        if (!checkIfPrinted) {
          // Push order number into check array
          printed.push(syspro_invoice_id)
          // Print the delivery note
          await getDeliveryNote({
            documentId: syspro_order_id,
            force_download: false,
            warehouseLocation: warehouseLocation.value
          })
        } else {
          console.log('📦 Already printed', syspro_invoice_id)

          Notify.create({
            message: `Delivery note for order ${syspro_invoice_id} has already been printed`,
            color: 'warning',
            textColor: 'black',
            position: 'bottom-right',
            timeout: 6000,
            // Remove item if incorrect
            actions: [
              {
                label: 'Remove',
                color: 'white',
                handler: () => {
                  const index = printed.indexOf(syspro_invoice_id)
                  if (index > -1) {
                    printed.splice(index, 1)
                    notifier({
                      message: `Delivery note for order ${syspro_invoice_id} has been removed from the printed list`,
                      type: 'warning'
                    })
                  }
                }
              }
            ]
          })
        }

        console.log('Printed Delivery Notes', printed)
      }
    )
    channelPrintDeliveryNotes.value.listening = true
  }
}

const leaveDeliveryNotes = () => {
  const { name, channel } = channelPrintDeliveryNotes.value

  if (name && channel) {
    // Stop listening for events
    channel.stopListening('.warehouse-print-order-event')
    // Leave the channel
    echo.leaveChannel(name)
    // Reset the channel
    channelPrintDeliveryNotes.value = {
      name: null,
      channel: null,
      listening: false
    }
    // Log
    console.log('🖨️ Left delivery notes channel', name)
  }
}

/**
 * ================================
 */

/**
 * ================================
 * Shipment Notes
 */

const listenForShipmentLabels = async (
  auto_print?: boolean,
  country?: string,
  userId?: number
) => {
  if (!auto_print || !country || !userId) return

  const printed = warehouseOrdersStore.printedShipmentLabels

  if (
    channelPrintShipmentLabels.value.name !==
    `warehouse-print-shipment-labels-${country}-${userId}`
  ) {
    leaveShipmentLabels()
  }

  if (channelPrintShipmentLabels.value.listening) return false

  const channelName =
    (channelPrintShipmentLabels.value.name = `warehouse-print-shipment-labels-${country}-${userId}`)

  if (!channelPrintShipmentLabels.value.channel) {
    // Join the channel
    channelPrintShipmentLabels.value.channel = echo.channel(channelName)
    // Log
    console.log('🖨️ Joined shipment labels channel', channelName)
    // Error handling
    channelPrintShipmentLabels.value.channel.error((error: unknown) =>
      console.log('Error', error)
    )
  }

  if (
    channelPrintShipmentLabels.value.channel &&
    !channelPrintShipmentLabels.value.listening
  ) {
    channelPrintShipmentLabels.value.channel.listen(
      '.warehouse-print-shipment-labels-event',
      (payload: channelCarrierLabelsPayloadType) => {
        const { orderId, labelId: label_id, shipmentId: shipment_id } = payload

        console.log('📦 Print shipment label payload', payload)
        // If it's not been printed, proceed with printing

        const checkIfPrinted = printed.includes(orderId)

        if (!checkIfPrinted) {
          // Push order number into check array
          printed.push(orderId)
          // Print the delivery note
          getCarrierLabels({
            label_id,
            force_download: false,
            shipment_id
          })
        } else {
          console.log('📦 Already printed', orderId)

          Notify.create({
            message: `Shipment label for order ${orderId} has already been printed`,
            color: 'warning',
            textColor: 'black',
            position: 'bottom-right',
            timeout: 6000,
            // Remove item if incorrect
            actions: [
              {
                label: 'Remove',
                color: 'white',
                handler: () => {
                  const index = printed.indexOf(orderId)
                  if (index > -1) {
                    printed.splice(index, 1)
                    notifier({
                      message: `Shipment label for order ${orderId} has been removed from the printed list`,
                      type: 'warning'
                    })
                  }
                }
              }
            ]
          })
        }
      }
    )
    channelPrintShipmentLabels.value.listening = true
  }
}

const leaveShipmentLabels = () => {
  const { name, channel } = channelPrintShipmentLabels.value

  if (name && channel) {
    // Stop listening for events
    channel.stopListening('.warehouse-print-shipment-labels-event')
    // Leave the channel
    echo.leaveChannel(name)
    // Reset the channel
    channelPrintShipmentLabels.value = {
      name: null,
      channel: null,
      listening: false
    }
    // Log
    console.log('🖨️ Left shipment labels channel', name)
  }
}

/**
 * ================================
 */

/**
 * ================================
 * Hooks
 * ================================
 */
const { mutateAsync: getDeliveryNote } =
  factories.warehouse.warehouseApiFactory.mutations.useGetShipmentDeliveryNote({
    onSuccess: (res, data) => {
      if (!data.force_download && import.meta.client) {
        new printStrategies().deliveryNote({
          contents: res.contents,
          file_type: 2,
          file_name: res.file_name
        })
      }
    }
  })

const { mutateAsync: getCarrierLabels } =
  factories.warehouse.warehouseApiFactory.mutations.useGetShipmentLabel({
    onSuccess: (res, data) => {
      if (!data.force_download && import.meta.client) {
        new printStrategies().carrierLabels({
          contents: res.contents,
          file_type: 2,
          file_name: res.file_name
        })
      }
    }
  })

/**
 * ================================
 * Watchers
 * ================================
 */

/**
 * Watch for auto print for delivery notes
 */
watchEffect(() => {
  /**
   * Delivery Notes
   */
  if (delivery_notes.value?.auto_print) {
    listenForDeliveryNotes(
      delivery_notes.value?.auto_print,
      country.value,
      user.value?.id
    )
  } else {
    leaveDeliveryNotes()
  }

  /**
   * Shipment Labels
   */

  if (carrier_labels.value?.auto_print) {
    listenForShipmentLabels(
      carrier_labels.value?.auto_print,
      country.value,
      user.value?.id
    )
  } else {
    leaveShipmentLabels()
  }
})

/**
 * ================================
 * Lifecycle
 * ================================
 */
</script>
