<template>
  <fr-page
    class="m-6 !w-full md:w-[40vw] md:min-w-[40vw] lg:w-[600px] lg:min-w-[600px]"
  >
    <fr-card v-if="printer">
      <fr-card-header title="Printer Configuration" show-close-button />
      <fr-card-body
        v-if="
          printer.printers.value.length > 0 &&
          printer.status.value === 'connected' &&
          printer.loading.value === false
        "
        class="flex h-full w-full !flex-col gap-2 md:!flex-row md:flex-nowrap"
      >
        <!-- Before -->
        <q-tabs v-model="tab" :vertical="true" dense>
          <q-tab
            v-for="section in sections"
            :key="section.file_name"
            class="justify-start"
            :name="section.file_name"
          >
            <div class="flex flex-col items-start">
              <p>{{ section.printer_name }}</p>
              <div>
                <q-chip
                  v-if="section.auto_print"
                  color="primary"
                  text-color="white"
                  label="Auto-print on"
                  size="xs"
                />

                <q-chip
                  v-if="section.printer"
                  color="primary"
                  text-color="white"
                  size="xs"
                >
                  <div class="space-x-1">
                    <q-icon name="fal fa-print" />
                    <q-icon name="fal fa-check" />
                  </div>
                </q-chip>
              </div>
            </div>
          </q-tab>
        </q-tabs>

        <!-- After -->
        <q-tab-panels
          v-model="tab"
          animated
          vertical
          transition-prev="jump-up"
          transition-next="jump-up"
          class="w-full flex-1"
        >
          <!-- Delivery Notes -->
          <q-tab-panel
            v-for="section in sections"
            :key="section.file_name"
            :name="section.file_name"
            class="p-0"
          >
            <div v-if="section" class="flex flex-row gap-3">
              <div class="grid w-full grid-cols-2 gap-3">
                <q-select
                  v-model="section.printer"
                  outlined
                  :options="printer.printers.value"
                  label="Printer"
                  option-label="name"
                  emit-value
                  class="col-span-2"
                  map-options
                  dense
                  clearable
                />
                <q-select
                  v-model="section.paper_size"
                  :disable="section.printer === null"
                  outlined
                  :options="section.printer?.papers"
                  label="Paper Size"
                  option-label="name"
                  option-value="name"
                  class="col-span-1"
                  emit-value
                  map-options
                  dense
                />
                <q-select
                  v-if="
                    section.paper_media !== null &&
                    section.file_name !== 'delivery_notes' &&
                    section.file_name !== 'carrier_labels'
                  "
                  v-model="section.paper_media"
                  outlined
                  class="col-span-1"
                  :options="pageSizeOptions"
                  label="Paper Media"
                  dense
                />
                <q-input
                  v-model="section.copies"
                  outlined
                  class="col-span-1"
                  label="Copies"
                  type="number"
                  dense
                  @update:model-value="
                    section.copies = Number(section.copies) || 1
                  "
                >
                  <q-tooltip>
                    How many copies do you want printed? Default is 1
                  </q-tooltip>
                </q-input>
                <q-toggle
                  v-model="section.paper_fit"
                  class="col-span-1"
                  label="Shrink to fit"
                  left-label
                />
                <q-toggle
                  v-model="section.paper_autocenter"
                  class="col-span-1"
                  label="Auto Center"
                  left-label
                />
                <q-toggle
                  v-model="section.paper_autorotate"
                  v
                  label="Auto Rotate"
                  left-label
                />
                <q-toggle
                  v-model="section.paper_grayscale"
                  class="col-span-1"
                  label="Print Grayscale"
                  left-label
                />

                <q-toggle
                  v-model="section.auto_print"
                  class="col-span-1"
                  label="Auto Print"
                  left-label
                />
              </div>
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </fr-card-body>

      <fr-card-body
        v-else-if="printer.loading.value"
        class="flex flex-col items-center justify-center"
      >
        <q-spinner-dots size="5rem" />
        <div class="text-h6">Loading printers...</div>
      </fr-card-body>

      <fr-card-body
        v-else
        class="flex flex-col items-center justify-center gap-3"
      >
        <div class="text-h6">No printers found</div>
        <div class="text-caption">
          Please check your printer connection and try again. If that doesn't
          work, please check the below possible solutions.
        </div>
        <q-list class="w-full" dense bordered>
          <q-expansion-item
            dense
            class="w-full"
            label="Click the reload button"
          >
            <q-separator />
            <q-card>
              <q-card-section>
                Sometimes the websocket takes longer then usual to connect.
                clicking the reload button can help.
              </q-card-section>
            </q-card>
          </q-expansion-item>
          <q-separator />
          <q-expansion-item
            dense
            class="w-full"
            label="Check your printer connection"
          >
            <q-separator />
            <q-card>
              <q-card-section>
                Check that your computer is connected to the same network as
                your printer. If you are using a wireless printer, make sure
                that your printer is turned on and connected to the same network
                as your computer.
              </q-card-section>
            </q-card>
          </q-expansion-item>
          <q-separator />
          <q-expansion-item
            dense
            class="w-full"
            label="Check that you have allowed the website to use the JS Print Manager"
          >
            <q-separator />
            <q-card>
              <q-card-section>
                Please check the following steps to allow the website to use the
                JS Print Manager:
                <ol class="flex flex-col gap-3">
                  <li>
                    Right click on the JS Print Manager icon in your bottom
                    right bar
                  </li>
                  <li>Click on "settings" in the popup menu that appears.</li>
                  <li class="flex flex-col gap-3">
                    <span>in "Sites Manager" add the following URL:</span>
                    <q-btn
                      class="bg-fr-gray-800 text-fr-gray-0"
                      @click="
                        () => {
                          fr_copyToClipboard(
                            'https://campfire.frontrunneroutfitters.com'
                          )
                        }
                      "
                    >
                      campfire.frontrunneroutfitters.com
                    </q-btn>
                  </li>
                  <li>
                    Once added, refresh the page and try again. If you are still
                    having issues, please contact support.
                  </li>
                </ol>
              </q-card-section>
            </q-card>
          </q-expansion-item>
          <q-separator />
          <q-expansion-item dense class="w-full" label="Try another browser">
            <q-separator />
            <q-card>
              <q-card-section>
                Some browsers have issues with the JS Print Manager. As it can
                use more than the usual websockets. We recommend Chrome or
                Firefox.
              </q-card-section>
            </q-card>
          </q-expansion-item>
        </q-list>
      </fr-card-body>

      <fr-card-footer class="sticky bottom-0">
        <q-btn
          v-if="printer.loading.value === false"
          :disable="printer.checking.value"
          :loading="printer.checking.value"
          icon="fal fa-sync"
          class="icon-only"
          @click="() => printer?.reload()"
        />
        <q-btn
          v-if="
            printer.printers.value.length > 0 &&
            printer.status.value === 'connected' &&
            printer.loading.value === false
          "
          label="Save"
          @click="saveDefaultPrinters()"
        />
      </fr-card-footer>
    </fr-card>
  </fr-page>
</template>

<script setup lang="ts">
import { useLabelsStore } from '~/features/warehouse/labels/store'
import { PrinterConfigFileNames } from '~/features/printer/store/types'
import type { PrinterType } from '~/features/printer/store/types'

const { $CampfirePrinter } = useNuxtApp()
// @ts-expect-error - $CampfirePrinter is not defined
const printer = new $CampfirePrinter()

const props = withDefaults(
  defineProps<{
    sections?: PrinterType[]
    tab?: PrinterConfigFileNames | string
  }>(),
  {
    sections: () => [],
    tab: PrinterConfigFileNames.deliveryNotes
  }
)

const warehouseLabelsStore = useLabelsStore()

const tab = ref(props.tab)
const sections = ref(props.sections)

const pageSizeOptions = computed(() => warehouseLabelsStore.pageSizeOptions)

function saveDefaultPrinters(): void {
  if (!printer) return
  printer.setDefaultConfigs(sections.value)

  printer.models.value.default_printer = false

  notifier({
    message: 'Default printer configuration saved.',
    type: 'success'
  })
}

onMounted(async () => {
  await printer?.check()
})

onBeforeMount(() => {
  if (!props.tab) {
    if (printer.printerConfigs.value.length > 0)
      tab.value = printer.printerConfigs.value[0]?.file_name || ''
  }

  sections.value = printer.printerConfigs.value
})
</script>
